import React, { useContext, useEffect } from 'react';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderImage from '../../components/Account/HeaderImage';
import { UserContext } from '../../App';

export default function Congratulation() {
    const navigate = useNavigate();
    const { setcurMainImageNum } = useContext(UserContext);
    const locationState = useLocation().state;

    useEffect(() => {
      setcurMainImageNum(2);
    }, [])
  
    return (

        <>
        <div id='congratulation'>
      <div className='headerImg'>
        <HeaderImage/>
      </div>

      <div className="vertically-center3">
      <div className='ParentDiv'>
      <div className='mssgeMainDiv'>
      <img src="/icons/verifiedCheck.svg"/>
      </div>
        <p className='title text-center'>Email verified</p>
        <p className='sub-title text-center'>Your email has been verified successfully. Click the button below to proceed with Login.</p>
        <div className="label-input">
          <button type="submit" onClick={()=> navigate('/Account/SignIn')}>Login</button>
        </div>

        <NavLink className="backsignUp" to="/Account/Signup#">
           <img src='/icons/backImgEmail.svg'/>
          <p>Back to Sign Up</p>
        </NavLink>
      </div>
      </div>
    </div>
        </>
    );
};