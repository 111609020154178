import React, { useEffect, useRef, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
// import Router, { useRouter } from 'next/router';
import { $CommonServiceFn } from '../../components/network/Service';
import { $Service_Url } from '../../components/network/UrlPath';
import Invalid from '../../components/Account/Invalid';
import Captcha from '../../components/Account/reCaptcha/Captcha';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../App';
import konsole from '../../components/control/Konsole';
import commonLib from '../../components/control/commonLib';
import toast, { Toaster } from 'react-hot-toast'
import HeaderImage from '../../components/Account/HeaderImage';
import { errorMsg } from '../../components/control/Constant';
import CommonInputContainer from '../../components/CommonInputContainer';
import { verifyPassword } from '../../components/auth0';

export default function ChangePassword(props) {
    const { setLoader, setWarning} = useContext(UserContext);
    const locationState = useLocation().state;
    const [searchParams] = useSearchParams();
    const resetKey = searchParams.get('resetLinkKey');
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [invalidLink, setInvalidLink] = useState(false);

    const [passwordType, setPasswordType] = useState('password');
    const [userDetails, setUserDetails] = useState();
    const [toastertext, setoastertext] = useState('')

    const [passworderr, setpassworderr] = useState('')
    const [password, setPassword] = useState('')
    const [passCritErr, setpassCritErr] = useState({})
    const startingTabIndex = props?.startTabIndex ?? 0;

    const [conformpassword, setconformpassword] = useState('')
    const [ip, setIp] = useState("::1");
    const captchaRef = useRef(null);

    let updatePassword = window?.location?.pathname?.toLowerCase()?.includes('resetpassword');


    useEffect(() => {
        if (toastertext !== '' && toastertext !== null && toastertext !== undefined) {
            // toast.error(toastertext)
            setWarning('warning', toastertext);
            setoastertext('')

        }
    }, [toastertext])

    const getCountryIpCode = async (callback) => {
        setLoader(true);
        let url = "https://geolocation-db.com/json/";
        let data = await fetch(url);
        let parsedData = await data.json();
        setLoader(false);
        konsole.log("dattaa", parsedData);
        callback(parsedData.IPv4);
        setIp(parsedData.IPv4);
    }

    useEffect(() => {
        window.addEventListener("popstate", commonLib.onBackButtonEvent); return () => {
            window.removeEventListener("popstate", commonLib.onBackButtonEvent);
            navigate(1);
        };
    }, [])


    useEffect(() => {
        konsole.log("locationstate resetKey", (resetKey !== undefined && resetKey !== ""), resetKey, typeof (resetKey));
        if (locationState == null && locationState?.signUpPlatform == undefined && (resetKey !== null && resetKey !== undefined && resetKey !== "") && locationState?.nature == undefined) {
            // getCountryIpCode((IpRes) => {
                const IpRes = "::1";
                konsole.log('dskjvnb-ip', IpRes);
                if (IpRes) {
                    setLoader(true);
                    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.getUserDetailsByUSerID + resetKey + "/" + IpRes, "", (response, errorData) => {
                        setLoader(false);
                        if (response) {
                            konsole.log('getParams', response);
                            setUserDetails(response);
                            setUsername(response?.data?.data?.userName);
                        } if (response == null || response?.data?.data?.auth0Id == null || response?.data?.data == null) {
                            setInvalidLink(true);
                            navigate('/Account/Invalid', {
                                state: "Invalid Link",
                                replace: true,
                            });
                        }
                        else if (errorData) {
                            setLoader(false)
                            konsole.log('err', errorData);
                        }
                    }
                    )
                }  
            // })
        }
        else if (locationState?.nature !== undefined && locationState?.nature == "NewUser") {
            const userDetails = JSON.parse(commonLib.getSessionStorage("userCred"));
            konsole.log("userDetails jsoon", userDetails);
            setUserDetails(userDetails);
            setUsername(userDetails?.emailUserId);
        }
        else {
            // setInvalidLink(true);
            navigate('/Account/Invalid', {
                state: "Invalid Link",
                replace: true,
            });
        }
    }, [resetKey])
    konsole.log('user', username)
    konsole.log('userDetails', userDetails);
    const handleChange = (event) => {
        // setSignUp({[event.target.id]: event.target.value});
    }

    const { register, setError, handleSubmit, watch, formState: { errors }, clearErrors} = useForm();
    const isValidate = (formData) => {
        let isAnyError = false;
        const { password, confirmPassword } = formData;

        if (!password) {
            setError("password", { type: "error", message: errorMsg.password.empty })
            isAnyError = true
        } else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*=_+\-<>{}[\]():";?',.\\\/|~`])[A-Za-z\d!@#$%^&*=_+\-<>{}[\]():";?',.\\\/|~`]{8,}$/.test(password) == false) {
            setError("password", { type: "error", message: errorMsg.password.invalid })
            isAnyError = true
        }

        if (!confirmPassword) {
            setError("confirmPassword", { type: "error", message: errorMsg.confirmPassword.empty })
            isAnyError = true
        } else if (password != confirmPassword) {
            setError("confirmPassword", { type: "error", message: errorMsg.confirmPassword.invalid })
            isAnyError = true
        }

        return isAnyError == false;
    }


    const onSubmit = async (data) => {
        konsole.log("calledsubmit", data)

        if (isValidate(data) != true) return;
        // let captchaValue = captchaRef.current.checkCaptcha();
        // if (captchaValue == null) {
        //     return;
        // }
        
        if (locationState == null && locationState?.signUpPlatform == undefined && (resetKey !== null && resetKey !== undefined && resetKey !== "") && locationState?.nature == undefined) {
            const input = {
                userName: userDetails.data.data.userName,
                userId: userDetails.data.data.userId,
                auth0Id: userDetails.data.data.auth0Id,
                password: data.password,
                accessIP: ip,
                forgotPasswordId: userDetails.data.data.forgotPasswordId
            }
            konsole.log("inputinput", input)
            setLoader(true);
            const isSameAsOldPassword = await verifyPassword(userDetails.data.data.userName, data.password);
            konsole.log(isSameAsOldPassword, "bjkbk");

            if(isSameAsOldPassword) {
                setError("password", { type: "error", message: "Your new password must be different from Old password" })
                // setWarning("warning", "Your new password must be different from Old password");
                setLoader(false);
                return;
            }

            $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postUpdateForgotPassword, input, (response, errorData) => {

                if (response) {
                    setLoader(false);
                    konsole.log('success', response);
                    if (response.data.data === true) {
                        setWarning('success', 'Your password has been changed successfully')
                        navigate('/Account/SuccessResetPassword', {
                            state: "Reset",
                            replace: true,
                        });
                    }
                }
                else if (errorData) {
                    konsole.log("errorDataerrorDataerrorData", errorData)
                    setLoader(false);
                    let errorcode = errorData?.data?.statusCode
                    if (errorcode == 404) {
                        setoastertext("User does not exist.")
                    } else if (errorcode == 401) {
                        setoastertext("Unauthorized to change password.")
                    } else if (errorcode == 409 || errorcode == 500 || errorcode == 502 || errorcode == 503) {
                        setoastertext("Unable to procees your request, Please try later.")
                    } else if (errorcode == 400) {
                        setoastertext('Invalid change password request')
                    } else {
                        setoastertext("Unable to procees your request, Please try later.")
                    }

                    konsole.log("errorerror", errorData?.data?.statusCode == 404)
                    konsole.log("err", errorData);
                    captchaRef.current.refreshCaptcha();
                }
            })
        }
        else {
            const userDetails = JSON.parse(commonLib.getSessionStorage("userCred"));
            let input = {
                "userActivationId": userDetails?.userActivationId,
                "userRegstrtnId": userDetails.userRegstrtnId,
                "otpId": locationState?.otpId,
                "userId": userDetails.userId,
                "activationKey": userDetails.activationKey,
                "signUpPlatform": locationState.signUpPlatform,
                "password": data.password,
                "clientIPAddress": "::1"
            }
            konsole.log("konsole user", JSON.stringify(input))
            setLoader(true);
            $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postActiveUser, input, (response, errorData) => {
                if (response) {
                    setLoader(false);
                    konsole.log('success active user', response);
                    if (response.data.data !== null) {
                        setWarning('success', 'Your password has been created successfully')
                        navigate('/Account/SuccessResetPassword', {
                            state: "Set",
                            replace: true,
                        });
                    }
                }
                else if (errorData) {
                    setLoader(false);
                    konsole.log("err", errorData);
                    captchaRef.current.refreshCaptcha();
                }
            })
        }
    }

    const passwordToggle = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        }
        else {
            setPasswordType('password')
        }
    }



    const onChangepassword = (e) => {
        let pass_id = e.target.id;
        let pass_value = e.target.value
        if (pass_id == 'password') {
            setPassword(pass_value)
        }
        if (pass_id == "cnf-password") {
            setconformpassword(pass_value)
        }


    }


    useEffect(() => {
        if (password !== '' && password !== null && password !== undefined && conformpassword !== null && conformpassword !== undefined && conformpassword !== '') {
            if (password == conformpassword) {
                setpassworderr(false)
            } else {
                setpassworderr(true)
            }

        }

    }, [password, conformpassword])

    const inputfieldcapital = (val) => {
        // const valueenter = val.target.value?.replace(/^\s+/g, '');
        // const valueid = val.target.id;
        // let value = valueenter.charAt(0).toUpperCase() + valueenter.slice(1);
        // konsole.log(val.target.value, val.target.id)  
        val.target.value = val.target.value?.trimStart(); 
    }

    const ErrorShower = ({ inputName }) => {
        return errors?.[inputName]?.message ? <p className='attention'>{errors?.[inputName]?.message}</p> : "";
    }
    
    konsole.log("checkPasswordCriteria",  passCritErr)

    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                clearErrors()
                handleSubmit(onSubmit)()
            }} id='changePassword'>
                <div>
                    <div className='headerImg ps-2'>
                        <HeaderImage />
                    </div>
                    <div className='vertically-center5'>
                        <div className='parentDiv'>
                        <div className='mssgeMainDiv mt-4'>
                            <img src="/icons/newPassLock.svg" />
                        </div>
                        {(updatePassword) ?
                        <> <p className='title text-center mt-1'>Set new password</p>
                        <p className='sub-title text-center mt-3'>Your new password must be diffrent to previously used password.</p></>
                        :
                        <><p className='title text-center mt-1'>Set Password</p>
                        <p className='sub-title text-center mt-3'>Set your account password</p></> 
                        }
                        {/* {errors?.mainError ? <p className='attention mb-4'>{errors?.mainError?.message}</p> : ""} */}
                        <CommonInputContainer
                            label="New Password"
                            error={errors?.password}>
                    <input tabIndex={startingTabIndex + 1} type={passwordType} placeholder='Enter password' className={`w-100 ${errors?.password ? 'input-error' : ''}`}
                              {...register("password", {
                              onChange: (e) => {;
                                e.target.value = e.target.value.trimStart();
                               commonLib.checkPasswordCriteria(e.target.value, setpassCritErr); 
                            //    setError("mainError", undefined);
                               }})}/>
                   <img src={(passwordType === 'password') ? '/icons/eye-off.svg' : '/icons/eye-show.svg'}
                    className={`toggle-icon img-fluid cursor-pointer ${errors?.password ? 'error' : ''}`} onClick={passwordToggle} alt='toggle visibility'/>
                        </CommonInputContainer>

                        <CommonInputContainer
                            label="Confirm Password"
                            error={errors?.confirmPassword}
                        >
                    <input tabIndex={startingTabIndex + 2} type={passwordType} placeholder='Enter your confirm password' className={`w-100 ${errors?.confirmPassword ? 'input-error' : ''}`}
                                {...register("confirmPassword", {
                                    onChange: (e) => { inputfieldcapital(e) }})}/>
                     <img src={(passwordType === 'password') ? '/icons/eye-off.svg' : '/icons/eye-show.svg'}
                       className={`toggle-icon img-fluid cursor-pointer ${errors?.confirmPassword ? 'error' : ''}`} onClick={passwordToggle} alt='toggle visibility'/>
                       </CommonInputContainer>
                        <div className='singUp-check'>
                            {passCritErr?.islen8 ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
                            {/* <input type='checkbox' className='custom-checkbox' checked={passCritErr?.islen8} /> */}
                            <p className='mb-0'>Must be at least 8 characters</p>
                        </div>
                        <div className='singUp-check'>
                            {passCritErr?.isUpperChar ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
                            {/* <input type='checkbox' className='custom-checkbox' checked={passCritErr?.isUpperChar} /> */}
                            <p className='mb-0'>Must contain at least one uppercase letter</p>
                        </div>
                        <div className='singUp-check'>
                            {passCritErr?.isalphaNum ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
                            {/* <input type='checkbox' className='custom-checkbox' checked={passCritErr?.isalphaNum} /> */}
                            <p className='mb-0'>Must contain alphanumeric characters</p>
                        </div>
                        <div className='singUp-check'>
                            {passCritErr?.isSpecialChar ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
                            {/* <input type='checkbox' className='custom-checkbox' checked={passCritErr?.isSpecialChar} /> */}
                            <p className='mb-0'>Must contain one special character</p>
                        </div>
                        <div className='label-input'>
                            <button tabIndex={startingTabIndex + 3} className='setResetBtn'  type="submit">{updatePassword == true ? "Reset password" : "Set password"}</button>
                        </div>
                        <NavLink tabIndex={startingTabIndex + 4} className="backsignUp" to="/Account/SignIn">
                            <img src='/icons/backImgEmail.svg' />
                            <p>Back to log in</p>
                        </NavLink>
                    </div>
                    </div>
                </div>
            </form>
        </>
    );
};